import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from '../api';
import {
  CreateMemoryDto,
  CreateMemoryResponseDto,
  EditMemoryDto,
  EditMemoryResponseDto,
  GetMemoriesResponseDto,
  GetMemoryByIdResponseDto,
  
} from './dto';
import {
  getCreateMemoryUrl,
  getEditMemoryUrl,
  getMemoriesUrl,
  getSignleMemoryUrl,
} from './urls';

const baseUrl = () => {
  return process.env.GATSBY_VERCEL_RELEASE_1_2 === 'TRUE' ||
    process.env.GATSBY_VERCEL_GIT_COMMIT_REF === 'main' ||
    process.env.GATSBY_AWS_ENV === 'PROD'
    ? 'https://memory.api.mystoriesmatter.com'
    : process.env.GATSBY_AWS_ENV === 'QA'
    ? 'https://qa-memory.api.mystoriesmatter.com'
    : 'https://dev-memory.api.mystoriesmatter.com';
};

export class MemoryApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config || {});

    // this middleware is been called right before the http request is made.
    this.interceptors.request.use((param: AxiosRequestConfig) => ({
      ...param,
    }));

    // this middleware is been called right before the response is get it by the method that triggers the request
    this.interceptors.response.use((param: AxiosResponse) => ({
      ...param,
    }));

    this.getMemories = this.getMemories.bind(this);
    this.createMemory = this.createMemory.bind(this);
    this.getMemoryById = this.getMemoryById.bind(this);
    this.editMemoryById = this.editMemoryById.bind(this);
  }

  public getMemories = (
    obj: AxiosRequestConfig
  ): Promise<GetMemoriesResponseDto> => {
    return this.get(getMemoriesUrl(), obj);
  };

  public createMemory = (
    createMemoryDto: CreateMemoryDto
  ): Promise<CreateMemoryResponseDto> => {
    return this.post(getCreateMemoryUrl(), createMemoryDto);
  };

  public getMemoryById = (id: string): Promise<GetMemoryByIdResponseDto> => {
    return this.get(getSignleMemoryUrl(id));
  };

  public editMemoryById = (
    id: string,
    editMemoryDto: EditMemoryDto
  ): Promise<EditMemoryResponseDto> => {
    return this.patch(getEditMemoryUrl(id), editMemoryDto);
  };

}


export const memoryApi = new MemoryApi({
  baseURL: baseUrl(),
});
