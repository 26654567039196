import { userApi } from '@/app-api/newApi/users';
import {
  ChangePasswordRequestDto,
  ResetPasswordDto,
  UpdateConfigRequestDto,
  UpdateUserInfoRequestDto,
} from '@/app-api/newApi/users/dto';
import { IConfig } from '@/app/reducers/Memory/memory.types';
import { createAsyncThunk } from '@reduxjs/toolkit';

const fetchUserDetails = createAsyncThunk(
  'user/userDetails',
  async (obj, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await userApi.getUserInfo();
      if (response?.data?.length > 0) {
        const { name, userID, email, config } = response.data[0];
        localStorage.setItem('fullName', `${name.first} ${name.last}`);
        localStorage.setItem('uid', userID);
        localStorage.setItem('email', email);
        Object.keys(config).forEach((key: string) => {
          type OnlyKeys = keyof typeof config;
          const value = config[key as OnlyKeys]
            ? config[key as OnlyKeys]!.toString()
            : 'false';
          localStorage.setItem(key, value);
        });
        return fulfillWithValue(response.data[0]);
      } else {
        return null;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const changePassword = createAsyncThunk(
  'user/changePassword',
  async (
    obj: ChangePasswordRequestDto,
    { fulfillWithValue, rejectWithValue }
  ) => {
    try {
      const res = await userApi.changePassword(obj);
      return fulfillWithValue(res);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const UpdateConfig = createAsyncThunk(
  'user/updateconfig',
  async (config: UpdateConfigRequestDto) => {
    const response = await userApi.updateConfig(config);
    return response;
  }
);

const initiateForgotPassword = createAsyncThunk(
  'user/initiateForgotPassword',
  async (email: string, { rejectWithValue, fulfillWithValue }) =>
    userApi
      .initiateForgotPassword(email)
      .then((res) => fulfillWithValue(res))
      .catch((err) => rejectWithValue(err))
);

const resetPassword = createAsyncThunk(
  'user/resetPassword',
  async (obj: ResetPasswordDto, { rejectWithValue, fulfillWithValue }) =>
    userApi
      .resetPassword(obj)
      .then((res) => fulfillWithValue(res))
      .catch((err) => rejectWithValue(err))
);
const updateUserDetails = createAsyncThunk(
  'user/update',
  async (obj: UpdateUserInfoRequestDto) => {
    try {
      const response = await userApi.updateUserInfo(obj);
      if (response.code === 200) {
        localStorage.setItem('fullName', obj.firstName + ' ' + obj.lastName);
      }
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const userActions = {
  fetchUserDetails,
  changePassword,
  UpdateConfig,
  updateUserDetails,
  initiateForgotPassword,
  resetPassword,
};
