import {
  createSlice,
  ActionReducerMapBuilder,
  Action,
  AnyAction,
  PayloadAction,
} from '@reduxjs/toolkit';
import { loginActions } from './login.actions';
import { ILoginState } from './login.types';

interface RejectedAction extends Action {
  payload: any;
  error: Error;
}

function isRejectedAction(action: AnyAction): boolean {
  const regex = new RegExp('login/[a-zA-Z]*/rejected');
  return regex.test(action.type);
}

function isLoadingAction(action: AnyAction): boolean {
  const regex = new RegExp('login/[a-zA-Z]*/pending');
  return regex.test(action.type);
}

const initialState: ILoginState = {
  status: 'idle',
  loginResponse: {},
  amplifyError: null,
};

const reducers = {
  clearLoginResponse: (state: ILoginState, action: Action) => {
    state.loginResponse = {};
  },
  setAmplifyError: (state: ILoginState, action: PayloadAction<any>) => {
    state.amplifyError = action.payload;
  },
  clearAmplifyError: (state: ILoginState) => {
    state.amplifyError = null;
  },
  setLoginResponse: (state: ILoginState, action: PayloadAction) => {
    state.loginResponse = action.payload;
  },
};

const extraReducers = (builder: ActionReducerMapBuilder<ILoginState>) => {
  builder
    .addCase(loginActions.loginWithNewApi.fulfilled, (state, action) => {
      state.loginResponse = action.payload;
      state.status = 'idle';
    })
    .addCase(loginActions.loginWithOldApi.fulfilled, (state, action) => {
      state.status = 'idle';
      state.loginResponse = action.payload;
    })
    .addCase(loginActions.googleLogin.fulfilled, (state, action) => {
      state.status = 'idle';
      state.loginResponse = action.payload;
    })
    .addCase(loginActions.ssoLogin.fulfilled, (state, action) => {
      state.status = 'idle';
      state.loginResponse = action.payload;
    })
    .addMatcher(isRejectedAction, (state, action) => {
      state.status = 'failed';
      state.error = action.error;
      state.loginResponse = action.payload;
    })
    .addMatcher(isLoadingAction, (state, action) => {
      state.status = 'loading';
    });
};

export const LoginSlice = createSlice({
  initialState,
  name: 'login',
  reducers,
  extraReducers,
});
export const {
  clearLoginResponse,
  setAmplifyError,
  clearAmplifyError,
  setLoginResponse,
} = LoginSlice.actions;
export default LoginSlice.reducer;
