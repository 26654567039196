import {
  createSlice,
  ActionReducerMapBuilder,
  AnyAction,
  PayloadAction,
} from '@reduxjs/toolkit';

import { signUpActions } from './signup.action';
import { IError, ISignupState, ISetValues } from './signup.types';

function isLoadingAction(action: AnyAction): boolean {
  const regex = new RegExp('signup/[a-zA-Z]*/pending');
  return regex.test(action.type);
}

const initialState: ISignupState = {
  status: 'idle',
  signupResponse: {},
  verifyAccountResponse: {},
  values: {
    firstName: '',
    lastName: '',
    email: '',
    birthYear: 1900,
    password: '',
    authRedirectURL: 'localhost:8000',
  },
  resendActivationEmailResponse: {},
};

const reducers = {
  setValues: (state: ISignupState, action: PayloadAction<ISetValues>) => {
    state.values = { ...action.payload };
    state.values.authRedirectURL = 'loaclhost:8000';
  },
  setPasswordValue: (state: ISignupState, action: PayloadAction<string>) => {
    state.values.password = action.payload;
  },
  clearVerifyAccountResponse: (state: ISignupState) => {
    state.verifyAccountResponse = {};
  },
};

const extraReducers = (builder: ActionReducerMapBuilder<ISignupState>) => {
  builder
    .addCase(signUpActions.register.fulfilled, (state, action) => {
      state.signupResponse = action.payload;
      state.status = 'idle';
    })
    .addCase(signUpActions.register.rejected, (state, action) => {
      state.signupResponse = action.payload as IError;
      state.error = action.error;
      state.status = 'failed';
    })
    .addCase(signUpActions.verifyAccount.fulfilled, (state, action) => {
      state.status = 'idle';
      state.verifyAccountResponse = action.payload;
    })
    .addCase(signUpActions.verifyAccount.rejected, (state, action) => {
      state.verifyAccountResponse = action.payload as any;
      state.error = action.error;
      state.status = 'failed';
    })
    .addCase(
      signUpActions.resendActivationEmail.fulfilled,
      (state, action: PayloadAction<{ code?: number; message?: string }>) => {
        state.status = 'idle';
        state.resendActivationEmailResponse = action.payload;
      }
    )
    .addMatcher(isLoadingAction, (state, action) => {
      state.status = 'loading';
    });
};

export const SignupSlice = createSlice({
  initialState,
  name: 'signup',
  reducers,
  extraReducers,
});
export const { setValues, setPasswordValue, clearVerifyAccountResponse } =
  SignupSlice.actions;
export default SignupSlice.reducer;
