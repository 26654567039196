const API_VERSION = 'api/v1';
const MICROSERVICE_NAME = 'memories';

export const getMemoriesUrl = () => `${API_VERSION}/${MICROSERVICE_NAME}/list`;

export const getSignleMemoryUrl = (id: string) =>
  `${API_VERSION}/${MICROSERVICE_NAME}/${id}`;

export const getCreateMemoryUrl = () => `${API_VERSION}/${MICROSERVICE_NAME}`;

export const getEditMemoryUrl = (id: string) =>
  `${API_VERSION}/${MICROSERVICE_NAME}/${id}`;

