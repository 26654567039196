import isBrowser from '@/util/gatsby';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { userApi } from '../../../app-api/newApi/users';
import {
  RegisterDto,
  RegisterResponseDto,
  ResendActivationEmailDto,
  VerifyAccountDto,
} from '../../../app-api/newApi/users/dto';

const register = createAsyncThunk(
  'signup/registerApi',
  async (obj: RegisterDto, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response: RegisterResponseDto = await userApi.register(obj);
      if (isBrowser) {
        localStorage.setItem('email', response.data.username);
        localStorage.setItem('uid', response.data.uid);
      }
      return fulfillWithValue(response);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const resendActivationEmail = createAsyncThunk(
  'signup/resend email',
  async (
    obj: ResendActivationEmailDto,
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const response = await userApi.resendActivationEmail(obj);
      return fulfillWithValue(response);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const verifyAccount = createAsyncThunk(
  'signup/verify-account',
  async (obj: VerifyAccountDto, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await userApi.verifyAccount(obj);
      return fulfillWithValue(response);
    } catch (err: any) {
      return rejectWithValue(err);
    }
  }
);

export const signUpActions = {
  register,
  resendActivationEmail,
  verifyAccount,
};
