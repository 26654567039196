interface IRoute {
  [k: string]: {
    path: string;
    title?: string;
    auth: boolean;
    component?: any;
  };
}
export const routes = {
  home: {
    path: '/',
    auth: true,
  },
  app: {
    auth: true,
    path: '/recent',
  },

  recent: {
    title: 'Recent',
    path: '/recent',
    auth: true,
    // subcomponent: RecentSub,
  },
  timeline: {
    title: 'Timeline',
    path: '/timeline',
    auth: true,
  },
  explore: {
    title: 'Explore',
    path: '/explore',
    auth: true,
  },
  edit: {
    title: 'Edit',
    path: '/edit',
    auth: true,

    // component: EditView,
  },
  prompts: {
    title: 'Prompts',
    path: '/prompts',
    auth: true,
    // component: Prompts,
  },
  notifications: {
    title: 'Notifications',
    path: '/notifications',
    auth: true,
    // component: Notifications,
  },
  settings: {
    title: 'Settings',
    path: '/settings',
    auth: true,
  },
  login: {
    title: 'Login',
    path: '/auth/login',
    auth: false,
  },
  signup: {
    title: 'Sign up',
    path: '/auth/signup',
    auth: false,
  },
  thankYouPage: {
    title: 'Thank you page',
    path: '/auth/thank-you',
    auth: false,
  },
  feedback: {
    path: 'https://us14.list-manage.com/survey?u=63d96d1bc9c76f87408c287f7&id=28fb8f660c&attribution=false',
    auth: false,
  },
  recoveryEmailSent: {
    path: '/auth/password-recovery/email-sent',
    auth: false,
  },
  userDetails: {
    path: '/auth/get-details',
    auth: false,
  },
} satisfies IRoute;

export const routeswithoutNavbar = {
  expandMemory: {
    title: 'Expand Memory',
    path: '/memories/',
    auth: true,
    // component: ExpandMemory,
  },
  newMemory: {
    title: 'Add Memory',
    path: '/memories/create',
    auth: true,
  },

  gallery: {
    title: 'Gallery',
    path: '/gallery',
    auth: true,
    // component: Gallery,
  },
  onboarding: {
    title: 'OnBoarding',
    path: '/onboarding',
    auth: true,
  },
  editMemory: {
    title: 'Edit Memory',
    path: '/memories/edit',
    auth: true,
  },
  authError: {
    title: 'Authentication Error',
    path: '/auth/error',
    auth: false,
  },
} satisfies IRoute;
