import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from '../api';
import { getTempUrlDto } from './dto';
import {getTempUrlsApiUrl} from './urls';

const baseUrl = () => {
  return process.env.GATSBY_VERCEL_RELEASE_1_2 === 'TRUE' ||
    process.env.GATSBY_VERCEL_GIT_COMMIT_REF === 'main' ||
    process.env.GATSBY_AWS_ENV === 'PROD'
    ? 'https://media.api.mystoriesmatter.com'
    : process.env.GATSBY_AWS_ENV === 'QA'
    ? 'https://qa-media.api.mystoriesmatter.com'
    : 'https://dev-media.api.mystoriesmatter.com';
};

export class MediaApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config || {});

    // this middleware is been called right before the http request is made.
    this.interceptors.request.use((param: AxiosRequestConfig) => ({
      ...param,
    }));

    // this middleware is been called right before the response is get it by the method that triggers the request
    this.interceptors.response.use((param: AxiosResponse) => ({
      ...param,
    }));
    this.getTempUrlApi = this.getTempUrlApi.bind(this);

  }
  public getTempUrlApi =(fileData:any):Promise<getTempUrlDto> => {
    return this.post(getTempUrlsApiUrl(),fileData);
  };
}

export const mediaApi = new MediaApi({
  baseURL: baseUrl(),
  headers:{
    'content-type':'multipart/form-data'
  }
});
