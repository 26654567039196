import configData from '../config/cognitoConfig';
import { Amplify, Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import isBrowser from '@/util/gatsby';

async function federatedSignIn(provider: CognitoHostedUIIdentityProvider) {
  return Auth.federatedSignIn({ provider }).catch((err) => err);
}
async function federatedSignOut() {
  return Auth.signOut().catch((err) => err);
}

function signInWithEmailAndPassword(email: string, password: string) {
  return Auth.signIn(email, password);
}

export { federatedSignIn, federatedSignOut, signInWithEmailAndPassword };
