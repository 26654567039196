import {
  createSlice,
  ActionReducerMapBuilder,
  Action,
  AnyAction,
  PayloadAction,
} from '@reduxjs/toolkit';
import { memoryActions } from './memory.actions';
import { IMemory, IMemoryState } from './memory.types';
import { DateTime } from 'luxon';
import isBrowser from '@/util/gatsby';
import { convertEpochToLocaleString } from '@/util/function';
import { JSONContent } from '@tiptap/react';

function isRejectedAction(action: AnyAction): boolean {
  const regex = new RegExp('memories/[a-zA-Z]*/rejected');
  return regex.test(action.type);
}

function isLoadingAction(action: AnyAction): boolean {
  if (action.type.toString().includes('memories/getTempUrl')) {
    return false;
  }
  const regex = new RegExp('memories/[a-zA-Z]*/pending');
  return regex.test(action.type);
}

const initialState: IMemoryState = {
  status: 'idle',
  error: null,
  memories: [],
  currentContextMenu: '-1',
  currentPage: 0,
  hasMore: false,
  publishDialogOpen: false,
  titleValidationDialogOpen: false,
  saveMemoryDialogOpen: false,
  createMemory: {
    title: '',
    content: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
        },
      ],
    },
    occurred: DateTime.now().toISO(),
    visibility: 1,
    author: isBrowser ? localStorage.getItem('uid')! : '',
    status: 1,
    tags: [],
    created: DateTime.now().toLocaleString(DateTime.DATE_MED),
  },
  editMemory: {
    title: '',
    content: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
        },
      ],
    },
    occurred: DateTime.now().toISO(),
    visibility: 1,
    author: isBrowser ? localStorage.getItem('uid')! : '',
    status: 1,
    tags: [],
    created: DateTime.now().toLocaleString(DateTime.DATE_MED),
  },
  currentMemory: null,
  tempUrl: null,
  show: false,
  ImageUploadStatus: 'idle',
};

const reducers = {
  setCurrentContextMenu: (
    state: IMemoryState,
    action: PayloadAction<{ id: string }>
  ) => {
    state.currentContextMenu = action.payload.id;
  },
  paginate: (state: IMemoryState, action: Action) => {
    state.currentPage += 1;
  },
  resetPagination: (state: IMemoryState, action: Action) => {
    state.currentPage = 0;
    state.hasMore = false;
  },
  resetMemories: (state: IMemoryState, action: Action) => {
    state.memories = [];
  },
  setDateForCreateMemory: (
    state: IMemoryState,
    action: PayloadAction<string>
  ) => {
    state.createMemory.occurred = action.payload;
  },
  setTitleForCreateMemory: (
    state: IMemoryState,
    action: PayloadAction<string>
  ) => {
    state.createMemory.title = action.payload;
  },
  setContentForTipTap: (state: IMemoryState, action: PayloadAction<any>) => {
    state.createMemory.content = action.payload;
  },
  togglePublishDialog: (state: IMemoryState, action: Action) => {
    state.publishDialogOpen = !state.publishDialogOpen;
  },
  clearCreateMemory: (state: IMemoryState, action: Action) => {
    state.createMemory = {
      title: '',
      content: [],
      occurred: DateTime.now().toISODate(),
      visibility: 1,
      author: isBrowser ? localStorage.getItem('uid')! : '',
      status: 1,
      tags: [],
      created: DateTime.now().toLocaleString(DateTime.DATE_MED),
    };
  },
  setCurrentMemory: (state: IMemoryState, action: PayloadAction<any>) => {
    state.currentMemory = action.payload;
  },
  toggleTitleValidationDialog: (state: IMemoryState, action: AnyAction) => {
    state.titleValidationDialogOpen = !state.titleValidationDialogOpen;
  },
  toggleSaveMemoryDialog: (state: IMemoryState, action: AnyAction) => {
    state.saveMemoryDialogOpen = !state.saveMemoryDialogOpen;
  },
  setDateForEditMemory: (
    state: IMemoryState,
    action: PayloadAction<string>
  ) => {
    state.editMemory.occurred = action.payload;
  },
  setTitleForEditMemory: (
    state: IMemoryState,
    action: PayloadAction<string>
  ) => {
    state.editMemory.title = action.payload;
  },
  setContentForEditMemory: (
    state: IMemoryState,
    action: PayloadAction<JSONContent>
  ) => {
    state.editMemory.content = action.payload;
  },
  setEditMemory: (
    state: IMemoryState,
    action: PayloadAction<IMemory | null>
  ) => {
    if (action.payload) {
      const memory = action.payload;
      state.editMemory = {
        author: memory.author.userID,
        content: memory.body.content,
        status: memory.status,
        title: memory.title,
        tags: memory.tags,
        visibility: memory.visibility,
        occurred: DateTime.fromISO(memory.date.occurred).toISO(),
        created: DateTime.fromISO(memory.date.created).toISO(),
        updated: DateTime.fromISO(memory.date.updated).toLocaleString(
          DateTime.DATE_MED
        ),
      };
    }
  },

  clearEditMemory: (state: IMemoryState, action: Action) => {
    state.editMemory = {
      title: '',
      content: [],
      occurred: '',
      visibility: 1,
      author: isBrowser ? localStorage.getItem('uid')! : '',
      status: 1,
      tags: [],
      created: DateTime.now().toLocaleString(DateTime.DATE_MED),
    };
  },
  setShow: (state: IMemoryState, action: Action) => {
    state.show = !state.show;
  },
};

const extraReducers = (builder: ActionReducerMapBuilder<IMemoryState>) => {
  builder
    .addCase(
      memoryActions.getAllMemoriesWithNewApi.fulfilled,
      (state, action) => {
        state.status = 'idle';
        if (state.memories.length > 0) {
          state.memories = [...state.memories, ...action.payload.data];
        } else {
          state.memories = action.payload.data;
        }
        state.hasMore = state.memories.length < action.payload.page.totalItems;
      }
    )
    .addCase(
      memoryActions.getCurrentUserMemoriesWithOldApi.fulfilled,
      (state, action) => {
        state.status = 'idle';
        if (state.memories.length > 0) {
          state.memories = [...state.memories, ...action.payload.data];
        } else {
          state.memories = action.payload.data;
        }
        state.hasMore = state.memories.length < action.payload.page.totalItems;
      }
    )
    .addCase(
      memoryActions.createMemoryWithNewApi.fulfilled,
      (state, action) => {
        state.status = 'idle';
        const first = localStorage.getItem('fullName')!.split(' ')[0];
        const last = localStorage.getItem('fullName')!.split(' ')[1];
        const name = { first, last };
        const newMemory: any = {
          ...action.payload.data[0],
          author: { name },
        };
        state.memories = [newMemory, ...state.memories];
      }
    )
    .addCase(memoryActions.draftMemoryWithNewApi.fulfilled, (state, action) => {
      state.status = 'idle';
    })
    .addCase(
      memoryActions.getMemoryByIdWithNewApi.fulfilled,
      (state, action) => {
        state.status = 'idle';
        state.currentMemory = action.payload.data[0];
      }
    )
    .addCase(memoryActions.GetTempUrl.fulfilled, (state, action) => {
      state.ImageUploadStatus = 'idle';
      state.tempUrl = action.payload.data.preSignedUrls;
    })
    .addCase(memoryActions.GetTempUrl.pending, (state, action) => {
      state.ImageUploadStatus = 'loading';
    })
    .addCase(memoryActions.GetTempUrl.rejected, (state, action) => {
      state.ImageUploadStatus = 'failed';
    })
    .addMatcher(isLoadingAction, (state, action) => {
      state.status = 'loading';
    })
    .addMatcher(isRejectedAction, (state, action) => {
      state.status = 'failed';
      state.error = action.error;
    });
};

const memorySlice = createSlice({
  name: 'memories',
  initialState,
  reducers,
  extraReducers,
});

export default memorySlice.reducer;

export const {
  setCurrentContextMenu,
  paginate,
  resetPagination,
  resetMemories,
  setDateForCreateMemory,
  setTitleForCreateMemory,
  setContentForTipTap,
  togglePublishDialog,
  clearCreateMemory,
  setCurrentMemory,
  toggleTitleValidationDialog,
  toggleSaveMemoryDialog,
  setDateForEditMemory,
  setTitleForEditMemory,
  setContentForEditMemory,
  setEditMemory,
  clearEditMemory,
  setShow,
} = memorySlice.actions;
